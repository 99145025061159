<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4 pl-3">
      <route-back-button/>
    </div>
    <edit-scheduled-message-modal
      ref="editScheduleMessageModalRef"
      :scheduleMessageIid="api_param.messageId"
      :categories="allCategories"
      @updateScheduleMessage="updateScheduleMessage"
    />
    <div class="container-fluid d-flex justify-content-between mt-4 mb-4 row">
      <h1 class="ml-3 d-inline-block mt-2">View Scheduled Message</h1>
      <base-button :loading="loaders.card" :class="{invisible: datePassed(scheduledMessage.date)}" class="btn-edit-scheduled-message float-right mr-3 mt-2 d-inline-block black-outline-button color-white custom-manage-buttons font-weight-normal"
      @click="toggleEditModal(api_param.messageId)">
        Edit
      </base-button>
    </div>
    <div class="container-fluid mt-1 row ">
      <div class="col-6">
        <card class="no-border-card d-flex justify-content-start align-items-lg-start" v-loading="loaders.card">
          <div class="card-title">
            <h2 class="schedule-detail-heading">MESSAGE</h2>
          </div>
          <div class="card-body p-0">
            <div class="row">
              <div class="col text-left">
                <p class="schedule-message-text">{{scheduledMessage.message}}</p>
              </div>
            </div>

          </div>
        </card>
      </div>
      <div class="col-6">
        <card class="no-border-card" v-loading="loaders.card">
          <div class="card-title">
            <h2 class="schedule-detail-heading">STAGES</h2>
          </div>

          <div class="card-body p-0">
            <div class="row">
              <div class="col-6 pb-2" v-for="stage in scheduledMessage.stages">
                <span class="schedule-message-text">{{stage}}</span>
              </div>
            </div>
          </div>

          <span class="d-block line w-100 mt-1"></span>
          <template v-if="categories.length>0">
            <template v-for="(category,index) in categories">
              <div class="card-title mt-3">
                <h2 class="schedule-detail-heading">PATIENT TAG ({{ category.name }})</h2>
              </div>

              <div class="card-body p-0">
                <div class="row">
                  <div class="col-6 pb-2" v-for="subCategory in category.user_sub_categories">
                    <span class="schedule-message-text">{{subCategory.name}}</span>
                  </div>
                </div>
              </div>
              <span v-if="index!==categories.length-1" class="d-block line w-100 mt-1"></span>
            </template>

          </template>
          <template v-else>
            <div v-loading="loaders.categories" class="card-title w-100 mt-3">
              <h2 class="schedule-detail-heading">PATIENT TAG {{ }}</h2>
            </div>
          </template>

        </card>
      </div>
    </div>
  </div>
</template>
<script>

import moment from 'moment'
import BackButton from "@/components/Router/BackButton";
import EditScheduledMessageModal from "@/views/Pages/Messages/ScheduledMessages/EditScheduledMessageModal";

export default {

  props: [],
  components: {
    RouteBackButton: BackButton,
    EditScheduledMessageModal
  },
  data() {
    return {
      loaders: {
        card: false,
        categories: false
      },
      allCategories:[],
      modals : {
        changeStageConfirmationModal:false,
      },
      api_param: {
        messageId: this.$route.params.id,
      },
      stages: {
        25: 'Considering Surgery',
        50: 'Pre-Op',
        75: 'Post-Op',
        150: 'Non-Surgical',
      },
      scheduledMessage : {
        message: '',
        stage: '',
        categories: [],
        date: new Date().toISOString().split('T')[0],
      },
      categories: []
    };
  },
  created() {
    this.getScheduledMessage();
  },
  mounted() {

  },
  methods: {

    fetchAllCategories() {
      const vm = this
      vm.loaders.modal = true
      axios.get(`${this.$store.getters.getBaseUrl}/api/user-category/1`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.allCategories = response.data.data
        })
        .catch((error) => console.error(error.response.message))
        .finally(() => vm.loaders.modal = false)
    },

    toggleEditModal(id) {
      this.fetchAllCategories();
      this.api_param.messageId = id;
      this.$refs.editScheduleMessageModalRef.resetFormFields();
      this.$refs.editScheduleMessageModalRef.getScheduledMessage(id);
      this.$store.commit('showEditScheduleMessageModal');
    },

    updateScheduleMessage() {
      this.$store.commit('hideEditScheduleMessageModal');
      this.$router.back();
    },

    fetchCategories() {
      const vm = this
      if (this.scheduledMessage.categories.length <= 0) return
      vm.loaders.categories = true
      axios.get(`${this.$store.getters.getBaseUrl}/api/user-category/sub-category`,
        {
          params: {
            user_category: this.scheduledMessage.categories
          }
        })
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.categories = response.data.data
        })
        .catch((error) => console.error(error.response.message))
        .finally(() => vm.loaders.categories = false)
    },

    getScheduledMessage() {
      let vm = this;
      vm.loaders.card = true;
      axios.get(vm.$store.getters.getBaseUrl + `/api/scheduled-messages/${vm.api_param.messageId}/show`)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.scheduledMessage.message = response.data.data.message;
          vm.scheduledMessage.date = response.data.data.date
          if(response.data.data.stage) {
            const stages = response.data.data.stage.split(',');
            vm.scheduledMessage.stages = stages.map(function (x) {
              return vm.stages[x];
            });
          }
          const stringCategories = response.data.data.user_category.split(',')
          // convert strings in array to ints
          for (const categoryId of stringCategories) {
            const num = parseInt(categoryId)
            if (typeof num === 'number' && !isNaN(num)) this.scheduledMessage.categories.push(num)
          }
          this.fetchCategories()
      }).catch(error => {
        console.log(error.response)
      }).finally(() => {
        vm.loaders.card = false;
      });
    },
    datePassed(date) {
      return (Date.parse(date) - Date.now() <= 0)
    },
  },
  computed: {
  },
  watch : {
  }
};
</script>

<style scoped>

.line{
  border: 1px solid #00000042;
  opacity: 0.36;
}

.schedule-detail-heading {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
  text-transform: uppercase;
  opacity: 1;
}

.schedule-message-text {
  text-align: left;
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
}

.btn-edit-scheduled-message {
  text-align: center;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;

  margin-right: 14px;
  padding: 1px 50px 1px 50px;
}

</style>

<style>
.no-border-card .card-footer {
  border-top: 0;
}

#basic-info-card .card-body {
  padding-top: 0 !important;
}

#basic-info-card .card-header {
  padding-bottom: 0 !important;
}

</style>
